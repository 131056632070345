import React from "react";
import styled, { css } from "styled-components"
import { TextField, Button, Card, CardContent } from "@material-ui/core"
import graciasImg from '../img/gracias.png';
import { Link } from "gatsby-theme-material-ui"

const StyledTextField = styled(TextField)`
  ${props =>
        css`
      input {
        color: #000 !important;
      }
      div:after, div:before {
        border-bottom: none;
      }
      div {
        color: #000 !important;
        background-color: #ffffff;
        box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        &:hover {
            background-color: #ffffff;
            box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        }
      }
      label {
        color: #000000;
        font-family: Raleway;
      }
      textarea {
        color: #000 !important;
      }
    `}
`

const FormEncuestaAgradecimiento = () => {
    return (
        <Styled>
            <div style={{ textAlign: 'center', padding: '2rem' }}>
                <h2 style={{ fontSize: '2rem', marginTop: '2rem', marginBottom: '1rem' }}>Encuesta de Satisfacción de Clientes</h2>
                <h4 style={{ color: 'grey', fontSize: '1.4rem', marginBottom: '1rem' }}>
                    Agradecemos el momento que nos brinda para ayudarnos a mejorar <br/>
                    respondiendo a las siguientes preguntas acerca de su experiencia con nosotros, <br/>
                    Hitss Argentina.
                </h4>
            </div>
            <div className="ContactForm">
                <Card style={{ width: '100%', maxWidth: '800px', padding: '1rem', margin: '1rem auto'}}>
                    <CardContent>
                        <div style={{ textAlign: 'center' }}>
                            <img src={graciasImg} alt="Icono de gracias" width="130" height="130" />
                            <p style={{fontSize: '35px'}}>Muchas gracias</p>
                            <p style={{fontSize: '23px'}}>
                                Por ayudarnos con nuestro proceso de mejora <br />
                                continua para brindar cada vez mejores servicios <br />
                                y soluciones a nuestros clientes.
                            </p>
                        </div>
                        <Link to="/">
                            <Button
                                type="button" 
                                variant="contained"
                                fullWidth={true}
                                className="submit"
                                style={{ backgroundColor: '#269094', color: '#fff' }}
                            >
                                Ir a Globalhitssconosur.com
                            </Button>
                        </Link>
                    </CardContent>
                </Card>
            </div>
        </Styled>
    )
}

const Styled = styled.div`
 .title {
    width: 100%;
    padding: '0px 70px';
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;

    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }

 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }

    .desc {

        padding-right: 15px;

        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;

            a {
                color: white !important;
            }
        }
    }
 }

 .formControl{
     min-height: 65px;     

     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }

 .form {
    margin-bottom: 0;
 }
`

export default FormEncuestaAgradecimiento;